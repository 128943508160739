<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <form
      @submit.prevent="handleSubmit"
      method="POST"
      action="/profile"
      enctype="multipart/form-data"
    >
      <div>
        <label>Select your profile picture:</label>
        <input type="file" name="profile_pic" @change="handleFileImageChange" />
      </div>
      <div>
        <input type="submit" name="btn_upload_profile_pic" value="Upload" />
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { ref, reactive } from "@vue/composition-api";

export default {
  setup() {
    const uploadUrl = process.env.VUE_APP_API_LINK;
    let fileItem = null;

    const handleSubmit = (event) => {
      console.log("handleSubmit", event);

      console.log("files ", fileItem[0]);

      const formData = new FormData(event.target);
      // formData.append("profile_pic", fileItem[0]);

      uploadData({
        method: "POST",
        body: formData,
      });
    };

    const handleFileImageChange = (event) => {
      // console.log(event);
      // console.log(event.target.files);
      fileItem = event.target.files;
    };

    async function uploadData(config) {
      console.log(config);
      try {
        const response = await fetch(uploadUrl, {
          mode: "cors",
          cache: "no-cache",
          ...config,
        });
        let data = await response.text();
        console.log(data);
      } catch {}
    }

    return {
      handleSubmit,
      handleFileImageChange,
    };
  },
  name: "Home",
  components: {
    // HelloWorld,
  },
};
</script>
